import axios from "axios";
import { Toast } from "../../util/Toast";
import {
	BLOCK_UNBLOCK_SWITCH,
	GET_USER,
	GET_HISTORY,
	EDIT_COIN,
} from "./types";

export const getUser =
	(start, limit, searchValue, sDate, eDate) => (dispatch) => {
		axios
			.get("/getUsers", {
				params: {
					start,
					limit,
					search: searchValue,
					startDate: sDate,
					endDate: eDate,
				},
			})
			// .then((response) => {
			// 	console.log("response: ", response);
			// 	response.json();
			// })
			.then((res) => {
				console.log("res: ", res);
				if (res.status === 200) {
					let male, female;
					const { data: resData } = res || {};
					if (resData.maleFemale) {
						// eslint-disable-next-line
						resData.maleFemale.map((data) => {
							if (data._id === "Female") return (female = data.gender);
							if (data._id === "Male") return (male = data.gender);
						});
					}
					dispatch({
						type: GET_USER,
						payload: {
							user: resData.user,
							activeUser: resData.activeUser,
							total: resData.total,
							male: male,
							female: female,
						},
					});
				} else {
					Toast("error", res.message);
				}
			})
			.catch((error) => Toast("error", error.message));
	};

export const handleBlockUnblockSwitch = (userId) => (dispatch) => {
	axios
		.patch(`blockUnblock/${userId}`)
		.then((res) => {
			if (res.data.status) {
				dispatch({ type: BLOCK_UNBLOCK_SWITCH, payload: res.data.user });
			} else {
				Toast("error", res.data.message);
			}
		})
		.catch((error) => Toast("error", error.message));
};

export const userHistory = (data) => (dispatch) => {
	axios
		.post(`history`, data)
		.then((res) => {
			if (res.data.status) {
				dispatch({ type: GET_HISTORY, payload: res.data });
			} else {
				Toast("error", res.data.message);
			}
		})
		.catch((error) => Toast("error", error.message));
};

export const editCoin = (data) => (dispatch) => {
	axios
		.post(`/user/addLessCoin`, data)
		.then((res) => {
			if (res.data.status) {
				dispatch({
					type: EDIT_COIN,
					payload: { data: res.data.user, id: data.userId },
				});
				Toast("success", "Update Successful!!");
			} else {
				Toast("error", res.data.message);
			}
		})
		.catch((error) => Toast("error", error.message));
};
